/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      thStyle: {
        width: '80px',
      },
    },
    {
      key: 'title',
      label: '标题',
      thStyle: {
        width: '380px',
      },
    },
    {
      key: 'image',
      label: '图片',
      thStyle: {
        width: '380px',
      },
    },
    { key: 'actions', label: '操作' },
  ]
  const perPage = ref(100)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refreshData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refreshData()
  })

  // 数据获取
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('birthday-module/fetchArticleList', {
        page: currentPage.value,
        title: searchQuery.value,
        pageSize: perPage.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteClassification = params => {
    store
      .dispatch('birthday-module/deleteArticle', {
        id: params,
      })
      .then(response => {
        if (response.code === 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refreshData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting classification',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    refInvoiceListTable,
    deleteClassification,
    refreshData,
  }
}
